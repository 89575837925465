import { Form } from '@remix-run/react'
import { z } from 'zod'
import { FacebookLogo } from '#app/components/icons/facebook-logo'
import { GoogleLogo } from '#app/components/icons/google-logo.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { useIsPending } from './misc'

export const GOOGLE_PROVIDER_NAME = 'google'
export const FACEBOOK_PROVIDER_NAME = 'facebook'
// to add another provider, set their name here and add it to the providerNames below

export const providerNames = [
	GOOGLE_PROVIDER_NAME,
	FACEBOOK_PROVIDER_NAME,
] as const
export const ProviderNameSchema = z.enum(providerNames)
export type ProviderName = z.infer<typeof ProviderNameSchema>

export const providerLabels: Record<ProviderName, string> = {
	[GOOGLE_PROVIDER_NAME]: 'Google',
	[FACEBOOK_PROVIDER_NAME]: 'Facebook',
} as const

export const providerIcons: Record<ProviderName, React.ReactNode> = {
	[GOOGLE_PROVIDER_NAME]: <GoogleLogo />,
	[FACEBOOK_PROVIDER_NAME]: <FacebookLogo />,
} as const

export function ProviderConnectionForm({
	redirectTo,
	type,
	providerName,
}: {
	redirectTo?: string | null
	type: 'Connect' | 'Login' | 'Signup'
	providerName: ProviderName
}) {
	const label = providerLabels[providerName]
	const formAction = `/auth/${providerName}`
	const isPending = useIsPending({ formAction })
	return (
		<Form
			className="flex items-center justify-center gap-2"
			action={formAction}
			method="POST"
		>
			{redirectTo ? (
				<input type="hidden" name="redirectTo" value={redirectTo} />
			) : null}
			<Button type="submit" className="h-auto w-full py-5" variant="outline">
				<span className="inline-flex items-center gap-3">
					{providerIcons[providerName]}
					{isPending ? (
						<span>Přesměrovávání...</span>
					) : (
						<span>
							{type === 'Login' ? 'Přihlásit' : 'Registrovat'} se přes {label}
						</span>
					)}
				</span>
			</Button>
		</Form>
	)
}
